export const baseUrl = 'http://admin.kissbalintmenza.hu/';
// export const baseUrl = process.env.BASE_URL;
// import { env } from 'app/env';
// export const baseUrl = env.REACT_APP_BASE_URL;

export const loginUrl = `${baseUrl}api/token/`;
export const planFoodsUrl = `${baseUrl}api/calendar-menu/`;
export const ordersUrl = `${baseUrl}api/orders/summary/`;
export const createOrderUrl = `${baseUrl}api/create-order/`;
export const studentUrl = `${baseUrl}api/students/`;
export const uploadStudentUrl = `${baseUrl}api/upload-csv/`;
export const createStudentUrl = `${baseUrl}api/create-student/`;
export const deactivateStudentUrl = `${baseUrl}api/set-inactive/`;
export const resentInviteStudentUrl = `${baseUrl}api/resend-invitation/`;
